import Head from '@gain/modules/head'
import React from 'react'
import PropTypes from 'prop-types'
import {
  INDUSTRY_ASSESSMENT_PATH,
  INDUSTRY_ASSETS_PATH,
  INDUSTRY_CHARTS_PATH,
  INDUSTRY_GENERAL_PATH,
  INDUSTRY_OUTLOOK_PATH,
  INDUSTRY_PREVIEW_PATH,
  INDUSTRY_SEGMENTS_PATH,
  INDUSTRY_SOURCES_PATH,
  INDUSTRY_SUMMARY_PATH,
} from '../../../../util/path'
import Text, { SIZE_HEADER_3 } from '../../../../Components/Text/Text'
import PageHeader from '../../../../Components/Layout/PageHeader'
import PageTitle from '../../../../Components/PageTitle/CenteredPageHeader'
import CenteredPageHeaderTitle from '../../../../Components/PageTitle/CenteredPageHeaderTitle'
import PageNavigationComposition from '../../../../Compositions/PageNavigation/PageNavigationComposition'
import PageHeaderButtons from '../../../../Components/PageTitle/PageHeaderButtons'
import ActionsMenu from '../../../../Compositions/ActionsMenu/ActionsMenu'
import ColoredDotIndicator from '../../../../Components/Indicators/colored-dot-indicator'
import IndustryActions from '../../../../Compositions/Actions/IndustryActions'
import StatusToggleButtonComposition from '../../../../Compositions/ItemActions/StatusToggleButtonComposition'
import isDisabled from '../../../../util/is-disabled'
import { CONS, GENERAL_INFO, PROS, SOURCES } from '../../../../routes/route-asset/route-asset-utils'
import PreviewButton from '../../../../common/preview-button'
import { openCreateNewsItems } from '../../../../redux/action/openCreateNewsItems'
import IndustryPublishedModal from '../../../../Compositions/Modals/IndustryPublishedModal'
import { ASSETS, CHARTS, KEY_TAKE_AWAYS, MARKET, OUTLOOK } from './industryDetailNames'

const createLinks = (data) =>
  [
    {
      title: 'General',
      path: INDUSTRY_GENERAL_PATH,
      tab: 'general',
      fieldNames: [GENERAL_INFO],
    },
    {
      title: 'Charts',
      path: INDUSTRY_CHARTS_PATH,
      tab: 'charts',
      fieldNames: [CHARTS, 'chart'],
    },
    {
      title: 'Summary',
      path: INDUSTRY_SUMMARY_PATH,
      tab: 'summary',
      fieldNames: [KEY_TAKE_AWAYS],
    },
    {
      title: 'Segments',
      path: INDUSTRY_SEGMENTS_PATH,
      tab: 'market',
      fieldNames: [MARKET],
    },
    {
      title: 'Outlook',
      path: INDUSTRY_OUTLOOK_PATH,
      tab: 'outlook',
      fieldNames: [OUTLOOK],
    },
    (data.pros.length > 0 || data.cons.length > 0) && {
      title: 'Assessment',
      path: INDUSTRY_ASSESSMENT_PATH,
      tab: 'assessment',
      fieldNames: [CONS, PROS],
    },
    {
      title: 'Companies',
      path: INDUSTRY_ASSETS_PATH,
      tab: 'assets',
      fieldNames: [ASSETS, 'asset'],
    },
    {
      title: 'Sources',
      path: INDUSTRY_SOURCES_PATH,
      tab: 'sources',
      fieldNames: [SOURCES],
    },
  ].filter(Boolean)

const IndustryDetailHeader = ({ data, params }) => {
  const links = createLinks(data).map((link) => ({ ...link, params }))

  return (
    <PageHeader>
      <PageTitle>
        <div />
        <CenteredPageHeaderTitle>
          <Head>
            <title>{data[GENERAL_INFO].title}</title>
          </Head>
          <Text size={SIZE_HEADER_3}>{data[GENERAL_INFO].title}</Text>
          <ColoredDotIndicator
            live={isDisabled(data)}
            title="industry research"
          />
        </CenteredPageHeaderTitle>
        <PageHeaderButtons>
          <PreviewButton
            to={INDUSTRY_PREVIEW_PATH}
            label={'Industry'}
            params={{
              id: data.id,
              title: data[GENERAL_INFO].title || '-',
            }}
          />
          <ActionsMenu content={<IndustryActions data={data} />} />
          <StatusToggleButtonComposition
            live={isDisabled(data)}
            next={openCreateNewsItems(<IndustryPublishedModal id={data.id} />)}
          />
        </PageHeaderButtons>
      </PageTitle>
      <PageNavigationComposition links={links} />
    </PageHeader>
  )
}

IndustryDetailHeader.propTypes = {
  data: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
}

export default IndustryDetailHeader
